import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const Interface = lazy(() => import('./components/Interface'));
const LoginSignup = lazy(() => import('./pages/LoginSignup'));
const Home = lazy(() => import('./pages/Home'));

const App = () =>
{
	return (
		<BrowserRouter>
		<Suspense>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/login" element={<LoginSignup mode={"login"} />} />
				<Route path="/signup" element={<LoginSignup mode={"signup"} />} />
				<Route path="/:page/:urlId?" element={<Interface />} />
			</Routes>
		</Suspense>
		<ToastContainer position="top-center" autoClose={10000} />
		</BrowserRouter>
	);
}

export default App;
